<script>
import api from '@/services/api';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import { copyObjectWithoutID } from '@/util/helper';
import { BCol, BFormCheckbox, BFormGroup, BFormInput, BRow } from 'bootstrap-vue';
import PageTitle from '@/components/PageTitle.vue';
import PasteImageFileAgent from '@/mixins/pasteImageFileAgent';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { MERCHANT_ID } from '@/constants/names';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import TournamentProductCard from '@/components/ui/TournamentProductCard.vue';
import TournamentProductItem from '@/views/tournament/tournaments/TournamentProductItem.vue';
import { checkLocales } from '@/util/lang';
import vSelect from 'vue-select';

export default {
    name: 'AppTournamentCRUD',
    mixins: [CRUDPageMixin, PasteImageFileAgent],
    components: {
        TournamentProductItem,
        TournamentProductCard,
        ProgressLinear,
        PageTitle,
        flatPickr,
        BCol,
        BFormCheckbox,
        BFormGroup,
        BRow,
        BFormInput,
        vSelect
    },

    data() {
        return {
            items: [],
            dataForModal: {
                name: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                type: '',
                position: null,
                start_date: null,
                end_date: null,
                gifts: [],
                is_published: false
            },
            typeOptions: [
                {
                    value: 'default',
                    label: 'default'
                },
                {
                    value: 'super',
                    label: 'super'
                }
            ],
            isUpdatingPage: false,
            updatingItem: null,
            requestPending: false
        };
    },

    async mounted() {
        this.requestPending = true;
        const params = this.$route.params;
        if (params?.id) {
            await this.getOneTournament(params.id);
            this.isUpdatingPage = true;
        }
        this.requestPending = false;
    },

    methods: {
        async getOneTournament(id) {
            const body = {
                id: id
            };
            await api.tournament
                .fetchOneTournament(body)
                .then((response) => {
                    let tournament = response.data.result;
                    tournament.gifts.forEach((gift, index) => {
                        tournament.gifts[index] = {
                            color: gift.color,
                            from_place: gift.from_place,
                            gift_id: gift.id,
                            position: gift.position,
                            id: gift.product.id,
                            name: checkLocales(gift.product.name),
                            images: gift.product.images,
                            to_place: gift.to_place,
                            type: 'update'
                        };
                    });
                    this.openModalToUpdate(tournament);
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        openModalToUpdate(item) {
            this.dataForModal = copyObjectWithoutID(item);
            this.updatingItem = item;
            Object.keys(this.updatingItem).forEach((key) => {
                if (key === 'images') {
                    for (let i = 0; i < this.dataForModal.images.length; i++) {
                        const image = this.dataForModal.images[i];
                        this.dataForModal.images[i] = {
                            id: image.id,
                            name: image.name,
                            size: image.size,
                            type: 'image/jpg',
                            url: image.path,
                            src: image.path
                        };
                    }
                }
            });
            this.isOpenModal = true;
        },

        async createTournament(body) {
            await api.tournament
                .createTournament(body)
                .then(async () => {
                    this.$toast.success(this.$t('success.added'));
                    if (this.createOneMore) {
                        setTimeout(() => {
                            location.reload();
                        }, 500);
                    } else {
                        await this.$router.push({ name: 'tournament-tournaments' });
                    }
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateTournament(body, config) {
            await api.tournament
                .updateTournament(body, config)
                .then(async () => {
                    this.$toast.success(this.$t('success.updated.title'));
                    await this.$router.push({ name: 'tournament-tournaments' });
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },
        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = JSON.parse(JSON.stringify(this.dataForModal));
                    body.merchant_id = MERCHANT_ID;

                    if (body.gifts && body.gifts.length) {
                        body.gifts.forEach((gift, index) => {
                            for (const [key, value] of Object.entries(gift)) {
                                if (key === 'id') {
                                    body.gifts[index]['product_id'] = value;
                                }
                                if (key === 'gift_id') {
                                    body.gifts[index]['id'] = value;
                                    delete body.gifts[index]['gift_id'];
                                }
                            }
                            if (gift.id === gift.product_id) {
                                delete body.gifts[index].id;
                            }
                        });
                    }

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            body.id = this.updatingItem.id;
                            await this.updateTournament(body, config);
                        } else {
                            await this.createTournament(body, config);
                        }
                    } catch (error) {
                        this.$toast.error(
                            error.response?.data?.message || error.message || this.$t('error.smth')
                        );
                    }
                }
            });
        },
        checkLocales(value) {
            if (typeof value === 'object') {
                if (this.localLang === 'uz') {
                    return value.uz;
                } else if (this.localLang === 'ru') {
                    return value.ru;
                } else {
                    return value.en;
                }
            }
            return value;
        },
        addGiftProduct() {
            this.dataForModal.gifts.push({
                color: '',
                from_place: '',
                position: this.dataForModal.gifts.length + 1,
                name: '',
                images: [],
                to_place: ''
            });
        },
        deleteGiftProduct(index) {
            this.dataForModal.gifts.splice(index, 1);
        },
        changeGiftProduct({ event, index }) {
            const changeItem = this.dataForModal.gifts[index];
            this.dataForModal.gifts[index] = Object.assign(changeItem, {
                id: event['id'],
                name: checkLocales(event.name),
                images: [event.image]
            });
        }
    }
};
</script>

<template>
    <div>
        <div class="d-flex justify-content-between align-items-center">
            <page-title>
                <template #title> {{ $t('tournament.tournament_details') }}</template>
            </page-title>

            <div class="float-right mb-1">
                <b-button variant="success" @click="submitModal">{{ $t('button.save') }}</b-button>
            </div>
        </div>

        <div>
            <progress-linear v-if="requestPending" />
            <div v-else class="mb-3">
                <b-row>
                    <b-col cols="12">
                        <ValidationObserver ref="createUpdateModal">
                            <b-row class="product-container">
                                <!--    NAME[UZ]    -->
                                <b-col cols="4">
                                    <label> {{ $t('titles.title') }} [UZ] </label>
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="`${$t('titles.title')} [UZ]`"
                                        rules="required"
                                    >
                                        <b-form-group>
                                            <b-form-input
                                                v-model="dataForModal.name.uz"
                                                :placeholder="`${$t('enter.title')} [UZ]`"
                                                :state="errors.length > 0 ? false : null"
                                                size="md"
                                            />
                                        </b-form-group>
                                        <span class="validation__red">
                                            {{ errors[0] }}
                                        </span>
                                    </ValidationProvider>
                                </b-col>

                                <!--    NAME[RU]    -->
                                <b-col cols="4">
                                    <label> {{ $t('titles.title') }} [RU] </label>
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="`${$t('titles.title')} [RU]`"
                                        rules="required"
                                    >
                                        <b-form-group>
                                            <b-form-input
                                                v-model="dataForModal.name.ru"
                                                :placeholder="`${$t('enter.title')} [RU]`"
                                                :state="errors.length > 0 ? false : null"
                                                size="md"
                                            />
                                        </b-form-group>
                                        <span class="validation__red">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </b-col>

                                <!--    NAME[EN]    -->
                                <b-col cols="4">
                                    <label> {{ $t('titles.title') }} [EN] </label>
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="`${$t('titles.title')} [EN]`"
                                        rules="required"
                                    >
                                        <b-form-group>
                                            <b-form-input
                                                v-model="dataForModal.name.en"
                                                :placeholder="`${$t('enter.title')} [EN]`"
                                                :state="errors.length > 0 ? false : null"
                                                size="md"
                                            />
                                        </b-form-group>
                                        <span class="validation__red">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </b-col>

                                <!--    START DATE    -->
                                <b-col cols="6">
                                    <label>{{ $t('titles.start_date') }}</label>
                                    <!--                        <b-form-datepicker-->
                                    <!--                            v-model="dataForModal.date"-->
                                    <!--                            class="mb-1"-->
                                    <!--                            style="z-index: 99"-->
                                    <!--                        />-->
                                    <div class="input-group">
                                        <flat-pickr
                                            v-model="dataForModal.start_date"
                                            :config="{
                                                enableTime: true,
                                                enableSeconds: true,
                                                allowInput: true,
                                                time_24hr: true
                                            }"
                                            :placeholder="`${$t('choose.date')}`"
                                            class="mb-1 form-control"
                                            name="date"
                                        />
                                    </div>
                                </b-col>

                                <!--    END DATE    -->
                                <b-col cols="6">
                                    <label>{{ $t('titles.end_date') }}</label>
                                    <!--                        <b-form-datepicker-->
                                    <!--                            v-model="dataForModal.date"-->
                                    <!--                            class="mb-1"-->
                                    <!--                            style="z-index: 99"-->
                                    <!--                        />-->
                                    <div class="input-group">
                                        <flat-pickr
                                            v-model="dataForModal.end_date"
                                            :config="{
                                                enableTime: true,
                                                allowInput: true,
                                                time_24hr: true
                                            }"
                                            :placeholder="`${$t('choose.date')}`"
                                            class="mb-1 form-control"
                                            name="date"
                                        />
                                    </div>
                                </b-col>

                                <!--    POSITION    -->
                                <b-col cols="4">
                                    <label> {{ $t('titles.position') }} </label>
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="`${$t('titles.position')}`"
                                        rules="required"
                                    >
                                        <b-form-group>
                                            <b-form-input
                                                v-model="dataForModal.position"
                                                :placeholder="`${$t('enter.position')}`"
                                                :state="errors.length > 0 ? false : null"
                                                size="md"
                                            />
                                        </b-form-group>
                                        <span class="validation__red">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </b-col>

                                <!--    TYPE    -->
                                <b-col cols="4">
                                    <label> {{ $t('titles.type') }} </label>
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="`${$t('titles.type')}`"
                                        rules="required"
                                    >
                                        <b-form-group>
                                            <v-select
                                                id="jamshid"
                                                v-model="dataForModal.type"
                                                :options="typeOptions"
                                                :placeholder="`${$t('enter.type')}`"
                                                :reduce="(type) => type.value"
                                            />
                                        </b-form-group>
                                        <span class="validation__red">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </b-col>

                                <!--    IS PUBLISHED    -->
                                <b-col cols="4">
                                    <label for="is_published">
                                        {{ $t('titles.is_published') }}
                                    </label>
                                    <b-form-checkbox
                                        id="is_published"
                                        v-model="dataForModal.is_published"
                                    />
                                </b-col>

                                <!--     PRODUCTS     -->
                                <b-row>
                                    <div
                                        v-for="(gift, index) in dataForModal.gifts"
                                        :key="index"
                                        class="col-12 col-md-6"
                                    >
                                        <b-col cols="12" class="d-flex flex-wrap">
                                            <b-col cols="10">
                                                <tournament-product-item
                                                    :key="index"
                                                    :index="index"
                                                    :product="gift"
                                                    @change-gift="changeGiftProduct"
                                                />
                                            </b-col>

                                            <b-col
                                                class="d-flex align-items-end justify-content-end"
                                                cols="2"
                                            >
                                                <b-button
                                                    variant="danger"
                                                    @click="deleteGiftProduct(index)"
                                                    class="px-1"
                                                    >X
                                                </b-button>
                                            </b-col>
                                        </b-col>

                                        <b-col v-if="Object.keys(gift)" class="p-2" cols="12">
                                            <tournament-product-card
                                                :key="gift.id"
                                                :is-updating-page="isUpdatingPage"
                                                :product="gift"
                                            />
                                        </b-col>
                                    </div>
                                </b-row>

                                <b-row class="w-100">
                                    <b-col cols="12">
                                        <div class="float-right">
                                            <b-button variant="primary" @click="addGiftProduct"
                                                >{{ $t('button.add') }}
                                            </b-button>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-row>
                        </ValidationObserver>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.product-container__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0;
    color: #4b465c;
}

.product-container {
    display: flex;
    margin: 0;
    row-gap: 1rem;
    padding: 2rem 1rem;
    border: 1px solid #dbdade;
}

::v-deep .vs__dropdown-menu {
    max-height: 250px !important;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

//.v-select {
//    background-color: #fff;
//}

.loading {
    text-align: center;
    position: absolute;
    color: #fff;
    z-index: 9;
    background: $purple;
    padding: 8px 18px;
    border-radius: 5px;
    left: calc(50% - 45px);
    top: calc(50% - 18px);
}
</style>
