var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"product-card"},[_c('div',{staticClass:"product-card-details"},[(_vm.product && _vm.product.images && _vm.product.images.length)?_c('div',{staticClass:"product-card-details-image"},[_c('img',{attrs:{"src":_vm.product['images'][0].path,"alt":""}})]):_c('div',{staticClass:"product-card-details-image"},[_c('img',{attrs:{"src":require('@/assets/images/icons/no-photo.png'),"alt":"no-photo"}})]),_c('h5',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.checkLocales(_vm.product['name']))+" ")])])]),_c('div',{staticClass:"product-card-form"},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.position'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.position'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.position'),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.product.position),callback:function ($$v) {_vm.$set(_vm.product, "position", $$v)},expression:"product.position"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.color'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.color'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.color'),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.product.color),callback:function ($$v) {_vm.$set(_vm.product, "color", $$v)},expression:"product.color"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.from_place'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.from_place'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.from_place'),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.product.from_place),callback:function ($$v) {_vm.$set(_vm.product, "from_place", $$v)},expression:"product.from_place"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.to_place'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.to_place'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.from_place'),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.product.to_place),callback:function ($$v) {_vm.$set(_vm.product, "to_place", $$v)},expression:"product.to_place"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }