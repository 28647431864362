<script>
import vSelect from 'vue-select';
import { BCol } from 'bootstrap-vue';
import api from '@/services/api';
import InfinityScrollSelect from '@/mixins/InfinityScrollSelect';
import { checkLocales } from '@/util/lang';

export default {
    name: 'TournamentProductItem',
    mixins: [InfinityScrollSelect],
    components: {
        vSelect,
        BCol
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        index: {
            type: [String, Number],
            required: true
        }
    },
    data() {
        return {
            productOptions: []
        };
    },
    async mounted() {
        this.optionsFilter.search = this.product.name || null;
        await this.getProducts();
    },
    methods: {
        async infiniteScroll([{ isIntersecting, target }]) {
            if (isIntersecting) {
                const ul = target.offsetParent;
                const scrollTop = target.offsetParent.scrollTop;
                this.optionsParams.page += 1;
                await this.getProducts();
                await this.$nextTick();
                ul.scrollTop = scrollTop;
            }
        },

        filterDebounce() {
            if (this.timeoutId !== null) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout((_) => {
                this.getProducts();
            }, 200);
        },

        async getProducts() {
            let query = this.optionsParams;
            if (this.optionsFilter.search) {
                query = {
                    ...this.optionsParams,
                    search: this.optionsFilter.search
                };
            }

            await api.tournament
                .fetchProducts(query)
                .then((response) => {
                    this.generatePagination(response.data.pagination);
                    const filteredList = response.data.result.map((item) => {
                        return {
                            id: item.id,
                            name: checkLocales(item.name),
                            image: item.images[0]
                        };
                    });
                    this.productOptions = [...this.productOptions, ...filteredList];
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.optionsFilter.loading = false;
                });
        },

        changeGiftProduct(event) {
            this.$emit('change-gift', { event: event, index: this.index });
        }
    }
};
</script>

<template>
    <b-col cols="12" class="px-0">
        <label>{{ $t('titles.products') }}</label>
        <!--                                      v-model="dataForModal.gifts[index]"-->

        <v-select
            :filterable="false"
            :multiple="false"
            :options="productOptions"
            :searchable="true"
            :selected="product"
            :value="product"
            label="name"
            @close="onClose"
            @input="changeGiftProduct"
            @open="onOpen"
            @search="filterDebounce"
        >
            <template #list-footer>
                <li v-show="hasNextPage" ref="load-scroll" class="loader">
                    Loading more options...
                </li>
            </template>
        </v-select>
    </b-col>
</template>

<style scoped></style>
