<script>
import { BCol, BFormGroup, BFormInput, BRow } from 'bootstrap-vue';
import { checkLang } from '@/util/helper';
import { checkLocales } from '../../util/lang';

export default {
    name: 'TournamentProductCard',
    methods: { checkLocales, checkLang },
    components: {
        BCol,
        BFormGroup,
        BRow,
        BFormInput
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        isUpdatingPage: {
            type: Boolean,
            require: true
        }
    }
};
</script>

<template>
    <div>
        <b-card class="product-card">
            <div class="product-card-details">
                <div
                    v-if="product && product.images && product.images.length"
                    class="product-card-details-image"
                >
                    <img :src="product['images'][0].path" alt="" />
                </div>
                <div v-else class="product-card-details-image">
                    <img :src="require('@/assets/images/icons/no-photo.png')" alt="no-photo" />
                </div>
                <h5 class="text-center">
                    <span>
                        {{ checkLocales(product['name']) }}
                    </span>
                </h5>
            </div>
            <div class="product-card-form">
                <ValidationObserver ref="createUpdateModal">
                    <b-row>
                        <!--    POSITION    -->
                        <b-col cols="6">
                            <label> {{ $t('titles.position') }} </label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.position')"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="product.position"
                                        :placeholder="$t('enter.position')"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </b-col>

                        <!--    COLOR    -->
                        <b-col cols="6">
                            <label> {{ $t('titles.color') }} </label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.color')"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="product.color"
                                        :placeholder="$t('enter.color')"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </b-col>

                        <!--    FROM_PLACE    -->
                        <b-col cols="6">
                            <label> {{ $t('titles.from_place') }} </label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.from_place')"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="product.from_place"
                                        :placeholder="$t('enter.from_place')"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </b-col>

                        <!--    TO_PLACE    -->
                        <b-col cols="6">
                            <label> {{ $t('titles.to_place') }} </label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.to_place')"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="product.to_place"
                                        :placeholder="$t('enter.from_place')"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </div>
        </b-card>
    </div>
</template>

<style lang="scss" scoped>
.product-card {
    &-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid var(--secondary);
        margin-bottom: 1rem;
        padding-bottom: 1rem;

        &-image {
            width: 70px;
            min-width: 70px;
            height: 100px;
            margin-bottom: 1rem;

            & img {
                width: 100%;
                min-width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}
</style>
