var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('tournament.tournament_details')))]},proxy:true}])}),_c('div',{staticClass:"float-right mb-1"},[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(_vm._s(_vm.$t('button.save')))])],1)],1),_c('div',[(_vm.requestPending)?_c('progress-linear'):_c('div',{staticClass:"mb-3"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',{staticClass:"product-container"},[_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" [UZ] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [UZ]"),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.name.uz),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "uz", $$v)},expression:"dataForModal.name.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" [RU] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [RU]"),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.name.ru),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "ru", $$v)},expression:"dataForModal.name.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" [EN] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [EN]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [EN]"),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.name.en),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "en", $$v)},expression:"dataForModal.name.en"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.start_date')))]),_c('div',{staticClass:"input-group"},[_c('flat-pickr',{staticClass:"mb-1 form-control",attrs:{"config":{
                                            enableTime: true,
                                            enableSeconds: true,
                                            allowInput: true,
                                            time_24hr: true
                                        },"placeholder":("" + (_vm.$t('choose.date'))),"name":"date"},model:{value:(_vm.dataForModal.start_date),callback:function ($$v) {_vm.$set(_vm.dataForModal, "start_date", $$v)},expression:"dataForModal.start_date"}})],1)]),_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.end_date')))]),_c('div',{staticClass:"input-group"},[_c('flat-pickr',{staticClass:"mb-1 form-control",attrs:{"config":{
                                            enableTime: true,
                                            allowInput: true,
                                            time_24hr: true
                                        },"placeholder":("" + (_vm.$t('choose.date'))),"name":"date"},model:{value:(_vm.dataForModal.end_date),callback:function ($$v) {_vm.$set(_vm.dataForModal, "end_date", $$v)},expression:"dataForModal.end_date"}})],1)]),_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.position'))+" ")]),_c('ValidationProvider',{attrs:{"name":("" + (_vm.$t('titles.position'))),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":("" + (_vm.$t('enter.position'))),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.position),callback:function ($$v) {_vm.$set(_vm.dataForModal, "position", $$v)},expression:"dataForModal.position"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.type'))+" ")]),_c('ValidationProvider',{attrs:{"name":("" + (_vm.$t('titles.type'))),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('b-form-group',[_c('v-select',{attrs:{"id":"jamshid","options":_vm.typeOptions,"placeholder":("" + (_vm.$t('enter.type'))),"reduce":function (type) { return type.value; }},model:{value:(_vm.dataForModal.type),callback:function ($$v) {_vm.$set(_vm.dataForModal, "type", $$v)},expression:"dataForModal.type"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"is_published"}},[_vm._v(" "+_vm._s(_vm.$t('titles.is_published'))+" ")]),_c('b-form-checkbox',{attrs:{"id":"is_published"},model:{value:(_vm.dataForModal.is_published),callback:function ($$v) {_vm.$set(_vm.dataForModal, "is_published", $$v)},expression:"dataForModal.is_published"}})],1),_c('b-row',_vm._l((_vm.dataForModal.gifts),function(gift,index){return _c('div',{key:index,staticClass:"col-12 col-md-6"},[_c('b-col',{staticClass:"d-flex flex-wrap",attrs:{"cols":"12"}},[_c('b-col',{attrs:{"cols":"10"}},[_c('tournament-product-item',{key:index,attrs:{"index":index,"product":gift},on:{"change-gift":_vm.changeGiftProduct}})],1),_c('b-col',{staticClass:"d-flex align-items-end justify-content-end",attrs:{"cols":"2"}},[_c('b-button',{staticClass:"px-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteGiftProduct(index)}}},[_vm._v("X ")])],1)],1),(Object.keys(gift))?_c('b-col',{staticClass:"p-2",attrs:{"cols":"12"}},[_c('tournament-product-card',{key:gift.id,attrs:{"is-updating-page":_vm.isUpdatingPage,"product":gift}})],1):_vm._e()],1)}),0),_c('b-row',{staticClass:"w-100"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"float-right"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.addGiftProduct}},[_vm._v(_vm._s(_vm.$t('button.add'))+" ")])],1)])],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }